import { keys } from '@material-ui/core/styles/createBreakpoints'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { LOGO } from '../res/constants'
import { setStatus } from '../store/actions/user'

const LoginPage: React.FC = () => {
    const [user, setUser] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState(false)
    const dispatch = useDispatch()

    const setLogin = () => {
        if (!user || !password) {
            return
        }
        setLoginError(false)
        if (user == "uspi" && password == "UspiNews") {
            dispatch(setStatus(true))
        } else {
            setLoginError(true)
        }
    }

    document.addEventListener('keypress', e => {
        if(e.code == "Enter") {
            setLogin()
        }
    })

    return (
        <div className="loginPage">
            <div className="container">
                <img src={LOGO} className="logo" />
                <input type="text" placeholder="Username" onChange={(e) => setUser(e.target.value)} />
                <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                <p
                    className="buttonLogin"
                    style={{ opacity: !user || !password ? 0.5 : 1 }}
                    onClick={() => setLogin()}
                >LOGIN</p>
                {loginError && (<p style={{ textAlign: "center", color: "#fff" }}>Login fallito. Dati di accesso errati.</p>)}
            </div>
        </div>
    )
}

export default LoginPage