import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerData, Reducers } from '../types/reducers'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemContent from '../components/ListItemContent';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import axios from 'axios';
import { CREATE_REGION, DELETE_REGION, EDIT_REGION } from '../res/constants';
import { getRegions } from '../store/actions/thunk_actions';
import { Region } from '../types/data';
import { Search } from '@material-ui/icons'

const RegionsPage: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const dispatch = useDispatch()
    const [filterQuery, setFilterQuery] = useState("")
    const [selectedItem, setSelectedItem] = useState<Region | undefined>()
    const [modalDeleteVisible, setModalDeleteVisible] = useState(false)
    const [modalEditVisible, setModalEditVisible] = useState(false)

    const regions = useMemo(() => {
        return (
            data.regions.filter((t) => t.name.toLowerCase().includes(filterQuery))
        )
    }, [data.regions, filterQuery])

    const handleDelete = async (id: string) => {
        await axios.delete(DELETE_REGION(id));
        dispatch(getRegions())
        setModalDeleteVisible(false)
    }

    const renderDeleteModal = () => {
        return (
            <Dialog open={modalDeleteVisible} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Vuoi cancellare questa regione?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Stai eliminando {selectedItem?.name}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setModalDeleteVisible(false)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={async () => {
                                //@ts-ignore
                                await handleDelete(selectedItem?._id.toString())
                            }}
                            color="secondary"
                        >
                            Elimina
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    const handleEdit = async (id: string) => {
        if (selectedItem?.name == "") {
            return
        }
        const body = {
            name: selectedItem?.name
        }
        if (selectedItem) {
            await axios.put(EDIT_REGION(selectedItem?._id.toString()), body);
            dispatch(getRegions())
            setModalEditVisible(false)
        }
    }

    const handleCreate = async () => {
        if (selectedItem?.name == "") {
            return
        }
        const body = {
            name: selectedItem?.name
        }
        await axios.post(CREATE_REGION, body);
        dispatch(getRegions())
        setModalEditVisible(false)
    }

    const renderEditModal = () => {
        return (
            <Dialog open={modalEditVisible} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">{selectedItem && selectedItem._id !== -1 ? "Modifica regione" : "Aggiungi regione"}</DialogTitle>
                <DialogContent>
                    <input
                        placeholder="Scrivi regione"
                        className="editItem"
                        value={selectedItem?.name}
                        onChange={(e) => {
                            //@ts-ignore
                            setSelectedItem({ ...selectedItem, name: e.target.value })
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setModalEditVisible(false)
                            }}
                            color="secondary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={async () => {
                                if (selectedItem?._id !== -1) {
                                    //@ts-ignore
                                    await handleEdit(selectedItem?._id.toString())
                                } else {
                                    await handleCreate()
                                }
                            }}
                            style={{ opacity: selectedItem?.name !== "" ? 1 : 0.4 }}
                            color="primary"
                        >
                            Salva
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            {renderDeleteModal()}
            {renderEditModal()}
            <div className="titleContainer">
                <h1 className="titleSection">Regioni</h1>
                <p
                    className="add"
                    onClick={() => {
                        setSelectedItem({
                            _id: -1,
                            name: ""
                        })
                        setModalEditVisible(true)
                    }}
                >
                    + Aggiungi regione
                </p>
            </div>
            <div className="filterContainer">
                <h2>Filtra</h2>
                <div style={{ position: "relative" }}>
                    <Search style={{ position: "absolute", color: "#666" }} />
                    <input placeholder="Nome regione" onChange={(e) => setFilterQuery(e.target.value)} />
                </div>
            </div>
            <List>
                {regions.map((r) => {
                    return (
                        <ListItem key={r._id.toString()}>
                            <ListItemContent
                                onEdit={(region) => {
                                    setSelectedItem(region)
                                    setModalEditVisible(true)
                                }}
                                onDelete={(region) => {
                                    setSelectedItem(region)
                                    setModalDeleteVisible(true)
                                }}
                                item={r}
                            />
                        </ListItem>
                    )
                })}

            </List>
        </>
    )
}

export default RegionsPage