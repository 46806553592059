import { Province, Region, Source, Tag } from '../../types/data';
import { ReduxAction } from './../../types/reducers';

export enum DATA_ACTIONS {
    SET_SOURCES = "dataActions/setSources",
    SET_REGIONS = "dataActions/setRegions",
    SET_TAGS = "dataActions/setTags",
    SET_PROVINCES = "dataActions/setProvinces"
}

export const setSources: ReduxAction<Source[]> = (sources: Source[]) => {
    return {
        type: DATA_ACTIONS.SET_SOURCES,
        payload: sources
    }
}

export const setRegions: ReduxAction<Region[]> = (regions: Region[]) => {
    return {
        type: DATA_ACTIONS.SET_REGIONS,
        payload: regions
    }
}

export const setTags: ReduxAction<Tag[]> = (tags: Tag[]) => {
    return {
        type: DATA_ACTIONS.SET_TAGS,
        payload: tags
    }
}

export const setProvinces: ReduxAction<Province[]> = (provinces: Province[]) => {
    return {
        type: DATA_ACTIONS.SET_PROVINCES,
        payload: provinces
    }
}


