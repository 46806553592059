import React, { useState } from 'react';
import { Source } from '../types/data';
import { Edit, Delete } from '@material-ui/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@material-ui/core'
import axios from 'axios';
import { DELETE_SOURCE } from '../res/constants';
import { useDispatch } from 'react-redux';
import { getSources } from '../store/actions/thunk_actions';
import { useHistory } from 'react-router';

interface Props {
    source: Source
}

const SourceCard: React.FC<Props> = (props) => {
    const source = props.source;
    const dispatch = useDispatch()
    const history = useHistory()
    const [modalVisible, setModalVisible] = useState(false)

    const handleDelete = async () => {
        await axios.delete(DELETE_SOURCE(source._id.toString()));
        dispatch(getSources())
        setModalVisible(false)
    }

    const renderModal = () => {
        return (
            <Dialog open={modalVisible} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Vuoi cancellare questa testata?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Stai eliminando {source.name}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setModalVisible(false)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={async () => {
                                await handleDelete()
                            }}
                            color="secondary"
                        >
                            Elimina
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Paper className="sourceCard" onClick={() => { history.push("/sources/detail", { source: source }) }} style={{ backgroundColor: "#efefef", paddingBottom: 10 }}>
            {renderModal()}
            <div className="sourceControl">
                <Edit
                    onClick={() => { history.push("/sources/detail", { source: source }) }}
                    style={{
                        background: "#21aeef",
                        color: "#fff",
                        padding: 10,
                        fontSize: 18,
                        borderRadius: 5,
                        marginBottom: 10
                    }}
                />
                <Delete
                    onClick={() => setModalVisible(true)}
                    style={{
                        background: "#fd5468",
                        color: "#fff",
                        padding: 10,
                        fontSize: 18,
                        borderRadius: 5,
                    }}
                />
            </div>
            <div className="sourceLogoCont">
                <img src={source.img} className="sourceLogo" />
            </div>
            <p className="sourceName">{source.name}</p>
        </Paper>
    )
}

export default SourceCard