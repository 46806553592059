import React, { useEffect } from 'react';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerGraphic, Reducers, ReducerUser } from './types/reducers';
import { BrowserRouter, Route } from 'react-router-dom'
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import SourcesPage from './pages/sources/SourcesPage';
import SourceDetailPage from './pages/sources/SourceDetailPage';
import TagsPage from './pages/TagsPage';
import RegionsPage from './pages/RegionsPage';
import ProvincesPage from './pages/ProvincesPage';
import Header from './components/Header';
import Footer from './components/Footer';
import SideBar from './components/SideBar';
import { initData } from './store/actions/thunk_actions';
import { isMobile } from 'react-device-detect'

const App: React.FC = () => {
  const user: ReducerUser = useSelector((store: Reducers) => store.user)
  const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
  const dispatch = useDispatch()

  useEffect(() => {
    if(graphic.showMobileMenu) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "inherit";
    }
  },[graphic.showMobileMenu])

  useEffect(() => {
    if (user.status) {
      dispatch(initData())
    }
  }, [user.status])

  return (
    <BrowserRouter>
      {!user.status ? (
        <Route exact path="/" component={LoginPage} />
      ) : (
        <div className="container">
          <SideBar />
          <Header />
          <div className="sectionCont">
            <Route exact path="/" component={HomePage} />
            <Route exact path="/sources" component={SourcesPage} />
            <Route exact path="/sources/detail" component={SourceDetailPage} />
            <Route exact path="/tags" component={TagsPage} />
            <Route exact path="/regions" component={RegionsPage} />
            <Route exact path="/provinces" component={ProvincesPage} />
          </div>
          <Footer />
        </div>
      )}
    </BrowserRouter>
  )
}

export default App;
