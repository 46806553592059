import { GRAPHIC_ACTIONS } from "./../actions/graphic";
import { ReduxActionData, ReducerGraphic } from "../../types/reducers";

const defaultValue: ReducerGraphic = {
  countToday: "",
  countAll: "",
  showMobileMenu: false,
};

const graphicState: (
  state: ReducerGraphic,
  actions: ReduxActionData<any>
) => ReducerGraphic = (state = defaultValue, action: ReduxActionData<any>) => {
  switch (action.type) {
    case GRAPHIC_ACTIONS.SET_COUNT_TODAY:
      return {
        ...state,
        countToday: action.payload,
      };
    case GRAPHIC_ACTIONS.SET_COUNT_ALL:
      return {
        ...state,
        countAll: action.payload,
      };
    case GRAPHIC_ACTIONS.SET_SHOW_MOBILE_MENU:
      return {
        ...state,
        showMobileMenu: action.payload,
      };
    default:
      return state;
  }
};

export default graphicState;
