import React from 'react'
import { Close } from '@material-ui/icons';
import { LOGO } from '../res/constants';
import { NavLink, useLocation } from 'react-router-dom'
import { ReducerGraphic, Reducers } from '../types/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect'
import { setShowMobileMenu } from '../store/actions/graphic';

const SideBar: React.FC = () => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const location = useLocation()
    const dispatch = useDispatch()

    if (isMobile && graphic.showMobileMenu) {
        return (
            <>
                <div className="overlay"></div>
                <Close style={{position: "absolute", top: 20, left: 20, background: "#fff", padding: 5, fontSize: 20, zIndex: 99}} onClick={() => dispatch(setShowMobileMenu(false))} />
                <div className="sidebar-mobile">
                    <div className="menu">
                        
                        <div style={{ marginTop: 50 }}>
                            <NavLink to="/" onClick={() => dispatch(setShowMobileMenu(false))} style={{ textDecoration: "none", }}>
                                <p className="menuLink"
                                    style={{
                                        backgroundColor: location.pathname == "/" ? "rgba(255,255,255,0.4)" : "transparent",
                                        borderRightColor: location.pathname == "/" ? "#f37920" : "transparent",
                                        borderRightWidth: 5,
                                        borderRightStyle: "solid"
                                    }}>Homepage</p>
                            </NavLink>
                            <NavLink to="/sources" onClick={() => dispatch(setShowMobileMenu(false))} style={{ textDecoration: "none" }}>
                                <p className="menuLink"
                                    style={{
                                        backgroundColor: location.pathname == "/sources" ? "rgba(255,255,255,0.4)" : "transparent",
                                        borderRightColor: location.pathname == "/sources" ? "#f37920" : "transparent",
                                        borderRightWidth: 5,
                                        borderRightStyle: "solid"
                                    }}>Testate</p>
                            </NavLink>
                            <NavLink to="/tags" onClick={() => dispatch(setShowMobileMenu(false))} style={{ textDecoration: "none" }}>
                                <p className="menuLink"
                                    style={{
                                        backgroundColor: location.pathname == "/tags" ? "rgba(255,255,255,0.4)" : "transparent",
                                        borderRightColor: location.pathname == "/tags" ? "#f37920" : "transparent",
                                        borderRightWidth: 5,
                                        borderRightStyle: "solid"
                                    }}>Tag / Argomenti</p>
                            </NavLink>
                            <NavLink to="/regions" onClick={() => dispatch(setShowMobileMenu(false))} style={{ textDecoration: "none" }}>
                                <p className="menuLink"
                                    style={{
                                        backgroundColor: location.pathname == "/regions" ? "rgba(255,255,255,0.4)" : "transparent",
                                        borderRightColor: location.pathname == "/regions" ? "#f37920" : "transparent",
                                        borderRightWidth: 5,
                                        borderRightStyle: "solid"
                                    }}>Regioni</p>
                            </NavLink>
                            <NavLink to="/provinces" onClick={() => dispatch(setShowMobileMenu(false))} style={{ textDecoration: "none" }}>
                                <p className="menuLink"
                                    style={{
                                        backgroundColor: location.pathname == "/provinces" ? "rgba(255,255,255,0.4)" : "transparent",
                                        borderRightColor: location.pathname == "/provinces" ? "#f37920" : "transparent",
                                        borderRightWidth: 5,
                                        borderRightStyle: "solid"
                                    }}>Province</p>
                            </NavLink>
                        </div>
                    </div>
                    <div className="dbStat">
                        <p>Articoli di oggi: {graphic.countToday}</p>
                        <p>Articoli totali: {graphic.countAll}</p>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className="sidebar">
            <div className="menu">
                <NavLink to="/">
                    <img src={LOGO} className="logo" />
                </NavLink>
                <div style={{ marginTop: 50 }}>
                    <NavLink to="/" style={{ textDecoration: "none", }}>
                        <p className="menuLink"
                            style={{
                                backgroundColor: location.pathname == "/" ? "rgba(255,255,255,0.4)" : "transparent",
                                borderRightColor: location.pathname == "/" ? "#f37920" : "transparent",
                                borderRightWidth: 5,
                                borderRightStyle: "solid"
                            }}>Homepage</p>
                    </NavLink>
                    <NavLink to="/sources" style={{ textDecoration: "none" }}>
                        <p className="menuLink"
                            style={{
                                backgroundColor: location.pathname == "/sources" ? "rgba(255,255,255,0.4)" : "transparent",
                                borderRightColor: location.pathname == "/sources" ? "#f37920" : "transparent",
                                borderRightWidth: 5,
                                borderRightStyle: "solid"
                            }}>Testate</p>
                    </NavLink>
                    <NavLink to="/tags" style={{ textDecoration: "none" }}>
                        <p className="menuLink"
                            style={{
                                backgroundColor: location.pathname == "/tags" ? "rgba(255,255,255,0.4)" : "transparent",
                                borderRightColor: location.pathname == "/tags" ? "#f37920" : "transparent",
                                borderRightWidth: 5,
                                borderRightStyle: "solid"
                            }}>Tag / Argomenti</p>
                    </NavLink>
                    <NavLink to="/regions" style={{ textDecoration: "none" }}>
                        <p className="menuLink"
                            style={{
                                backgroundColor: location.pathname == "/regions" ? "rgba(255,255,255,0.4)" : "transparent",
                                borderRightColor: location.pathname == "/regions" ? "#f37920" : "transparent",
                                borderRightWidth: 5,
                                borderRightStyle: "solid"
                            }}>Regioni</p>
                    </NavLink>
                    <NavLink to="/provinces" style={{ textDecoration: "none" }}>
                        <p className="menuLink"
                            style={{
                                backgroundColor: location.pathname == "/provinces" ? "rgba(255,255,255,0.4)" : "transparent",
                                borderRightColor: location.pathname == "/provinces" ? "#f37920" : "transparent",
                                borderRightWidth: 5,
                                borderRightStyle: "solid"
                            }}>Province</p>
                    </NavLink>
                </div>
            </div>
            <div className="dbStat">
                <p>Articoli di oggi: {graphic.countToday}</p>
                <p>Articoli totali: {graphic.countAll}</p>
            </div>
        </div>
    )
}

export default SideBar