import React from 'react'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers } from '../types/reducers'
import { Grid } from '@material-ui/core'
import { RssFeed, LocationOn, LocalOffer } from '@material-ui/icons';
import SourceCard from '../components/SourceCard'
import { useHistory } from 'react-router';

const HomePage: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)

    const history = useHistory()

    const renderCounters = () => {
        return (
            <div className="countersContainer">
                <div className="sourcesCounter counterCont" onClick={() => { history.push("/sources") }}>
                    <div>
                        <span className="count">
                            {data.sources.length}
                        </span><br />
                        <span className="label">
                            Testate
                        </span>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <RssFeed className="icon" /><br />
                    </div>
                </div>
                <div className="regionsCounter counterCont" onClick={() => { history.push("/regions") }}>
                    <div>
                        <span className="count">
                            {data.regions.length}
                        </span><br />
                        <span className="label">
                            Regioni
                        </span>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <LocationOn className="icon" /><br />
                    </div>
                </div>
                <div className="tagsCounter counterCont" onClick={() => { history.push("/tags") }}>
                    <div>
                        <span className="count">
                            {data.tags.length}
                        </span><br />
                        <span className="label">
                            Tag
                        </span>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <LocalOffer className="icon" /><br />
                    </div>
                </div>
            </div>
        )
    }

    const latestSources = () => {
        return (
            <>
                <h2 className="titleSection">Ultime testate inserite</h2>
                <Grid container spacing={2}>
                    {data.sources.slice(data.sources.length - 10, data.sources.length).reverse().map((s) => {
                        return (
                            <Grid key={s._id.toString()} item xs={12} lg={3}>
                                <SourceCard source={s} />
                            </Grid>
                        )
                    })}
                </Grid>
            </>
        )
    }

    return (
        <>
            {renderCounters()}
            {latestSources()}
        </>
    )
}

export default HomePage