import React from 'react';
import { Tag, Region, Province } from '../types/data';
import { Edit, Delete } from '@material-ui/icons';

interface Props {
    item: Tag | Region | Province,
    onEdit: (item:Tag | Region | Province) => void
    onDelete: (item: Tag | Region | Province) => void
}

const ListItemContent: React.FC<Props> = (props) => {
    const item = props.item;

    return (
        <div className="listItem">
            <p className="itemName">{item.name}</p>
            <div className="itemControl">
                <Edit onClick={() => props.onEdit(item)} style={{ background: "#21aeef", color: "#fff", padding: 10, fontSize: 18, borderRadius: 5, marginRight: 10, cursor: "pointer" }} />
                <Delete onClick={() => props.onDelete(item)} style={{ background: "#fd5468", color: "#fff", padding: 10, fontSize: 18, borderRadius: 5, cursor: "pointer" }} />
            </div>
        </div>
    )
}

export default ListItemContent