import { ReduxAction } from './../../types/reducers';

export enum GRAPHIC_ACTIONS {
    SET_COUNT_TODAY = "graphicActions/setCountToday",
    SET_COUNT_ALL = "graphicActions/setCountAll",
    SET_SHOW_MOBILE_MENU = "graphicActions/setShowMobileMenu",
}

export const setCountToday: ReduxAction<string> = (count: string) => {
    return {
        type: GRAPHIC_ACTIONS.SET_COUNT_TODAY,
        payload: count
    }
}

export const setCountAll: ReduxAction<string> = (count: string) => {
    return {
        type: GRAPHIC_ACTIONS.SET_COUNT_ALL,
        payload: count
    }
}

export const setShowMobileMenu: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: GRAPHIC_ACTIONS.SET_SHOW_MOBILE_MENU,
        payload: status
    }
}


