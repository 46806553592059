export const GET_COUNT =
  "https://api2.informazioneitaliana.info/client/content_counter";

export const LOGO =
  "https://22hbgtest.s3.eu-west-2.amazonaws.com/app_res/uspi/logo.png";
export const LOGO_BLACK =
  "https://22hbgtest.s3.eu-west-2.amazonaws.com/app_res/uspi/logo-uspi-black.png";

export const GET_SOURCES =
  "https://api2.informazioneitaliana.info/client/feeds";
export const GET_REGIONS =
  "https://api2.informazioneitaliana.info/client/regions";
export const GET_TAGS = "https://api2.informazioneitaliana.info/client/tags";
export const GET_PROVINCES =
  "https://api2.informazioneitaliana.info/client/provinces";

export const CREATE_SOURCE =
  "https://api2.informazioneitaliana.info/client/feed";
export const CREATE_REGION =
  "https://api2.informazioneitaliana.info/client/region";
export const CREATE_TAG = "https://api2.informazioneitaliana.info/client/tag";
export const CREATE_PROVINCE =
  "https://api2.informazioneitaliana.info/client/province";

export const EDIT_SOURCE = (id: string) => {
  return "https://api2.informazioneitaliana.info/client/feed/" + id;
};

export const EDIT_REGION = (id: string) => {
  return "https://api2.informazioneitaliana.info/client/region/" + id;
};

export const EDIT_TAG = (id: string) => {
  return "https://api2.informazioneitaliana.info/client/tag/" + id;
};

export const EDIT_PROVINCE = (id: string) => {
  return "https://api2.informazioneitaliana.info/client/province/" + id;
};

export const DELETE_SOURCE = (id: string) => {
  return "https://api2.informazioneitaliana.info/client/feed/" + id;
};

export const DELETE_REGION = (id: string) => {
  return "https://api2.informazioneitaliana.info/client/region/" + id;
};

export const DELETE_TAG = (id: string) => {
  return "https://api2.informazioneitaliana.info/client/tag/" + id;
};

export const DELETE_PROVINCES = (id: string) => {
  return "https://api2.informazioneitaliana.info/client/province/" + id;
};
