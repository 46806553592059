import React, { useState } from 'react'
import { Province, Source } from '../../types/data';
import { ArrowBack } from '@material-ui/icons';
import axios from 'axios';
import { CREATE_SOURCE, DELETE_SOURCE, EDIT_SOURCE, LOGO_BLACK } from '../../res/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getSources } from '../../store/actions/thunk_actions';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@material-ui/core'
import { ReducerData, Reducers } from '../../types/reducers';
import Select from 'react-select';
import { getDatasAndSelect, itemsForSelect } from '../../utils/functions';

interface Props {
    history: any
}

const SourceDetailPage: React.FC<Props> = (props) => {
    const source: Source = props.history.location.state.source;
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const dispatch = useDispatch()
    const [currentId, setCurrentId] = useState(source._id);
    const [currentName, setCurrentName] = useState(source.name);
    const [currentFeed, setCurrentFeed] = useState(source.feed)
    const [currentLogo, setCurrentLogo] = useState(source.img)
    const [currentRegion, setCurrentRegion] = useState(source.region !== null ? source.region : data.regions[0])
    const [currentTag, setCurrentTag] = useState(source.tag !== null ? source.tag : data.tags[0])
    const [currentProvinces, setCurrentProvinces] = useState(source.provinces)
    const [currentSynonyms, setCurrentSynonyms] = useState(source.synonyms)
    const [changed, setChanged] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)

    const handleEdit = async () => {
        if (!changed || !currentName || !currentFeed) {
            return
        }
        const body = {
            name: currentName,
            feed: currentFeed,
            img: currentLogo,
            region: currentRegion,
            tag: currentTag,
            provinces: currentProvinces,
            synonyms: currentSynonyms
        }
        await axios.put(EDIT_SOURCE(currentId.toString()), body)
        dispatch(getSources());
        setChanged(false)
    }

    const handleCreate = async () => {
        if (!changed && !currentName && !currentFeed) {
            return
        }
        const body = {
            name: currentName,
            feed: currentFeed,
            img: currentLogo ? currentLogo : LOGO_BLACK,
            region: currentRegion ? currentRegion : data.regions[0],
            tag: currentTag ? currentTag : data.tags[0],
            provinces: currentProvinces,
            synonyms: currentSynonyms ? currentSynonyms : currentName
        }
        await axios.post(CREATE_SOURCE, body).then((res) => {
            setCurrentId(res.data._id)
            if (!currentLogo) {
                setCurrentLogo(LOGO_BLACK)
            }
            if (!currentSynonyms) {
                setCurrentSynonyms(currentName)
            }
            dispatch(getSources());
            setChanged(false)
        })
    }

    const handleDelete = async () => {
        await axios.delete(DELETE_SOURCE(currentId.toString()));
        setModalVisible(false);
        props.history.goBack();
        dispatch(getSources())
    }

    const renderModal = () => {
        return (
            <Dialog open={modalVisible} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Vuoi cancellare questa testata?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Stai eliminando {currentName}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setModalVisible(false)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={async () => {
                                await handleDelete()
                            }}
                            color="secondary"
                        >
                            Elimina
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <div>
            {renderModal()}
            <div className="titleDetail">
                <ArrowBack style={{ marginRight: 20, cursor: "pointer" }} onClick={() => { props.history.goBack() }} />
                <h2>{currentName ? currentName : "Nuova testata"}</h2>
            </div>
            <div className="formDetail">
                <div className="dataCol">
                    <label>Nome</label>
                    <input
                        value={currentName}
                        onChange={(e) => {
                            setCurrentName(e.target.value)
                            setChanged(true)
                        }}
                    />
                    <label>Feed</label>
                    <input
                        value={currentFeed}
                        onChange={(e) => {
                            setCurrentFeed(e.target.value)
                            setChanged(true)
                        }}
                    />
                    <label>Logo</label>
                    <input
                        value={currentLogo}
                        onChange={(e) => {
                            setCurrentLogo(e.target.value)
                            setChanged(true)
                        }}
                    />
                    <label>Regioni</label>
                    <Select
                        defaultValue={{
                            id: source.region !== null ? source.region._id : data.regions[0]._id,
                            value: source.region !== null ? source.region._id : data.regions[0]._id,
                            label: source.region !== null ? source.region?.name : data.regions[0].name
                        }}
                        options={itemsForSelect(data.regions)}
                        onChange={(event: any) => {
                            setChanged(true);
                            setCurrentRegion(event._id)
                        }}
                    />
                    <label>Tag</label>
                    <Select
                        defaultValue={{
                            id: source.tag !== null ? source.tag._id : data.tags[0]._id,
                            value: source.tag !== null ? source.tag._id : data.tags[0]._id,
                            label: source.tag !== null ? source.tag?.name : data.tags[0].name
                        }}
                        options={itemsForSelect(data.tags)}
                        onChange={(event: any) => {
                            setChanged(true);
                            setCurrentTag(event._id)
                        }}
                    />
                    <label>Province</label>
                    <Select
                        defaultValue={getDatasAndSelect(source.provinces)}
                        isMulti
                        options={itemsForSelect(data.provinces)}
                        onChange={(event: any) => {
                            setChanged(true);
                            if (!event) {
                                setCurrentProvinces([])
                                return
                            }
                            const newArray: Province[] = event.map((p: any) => {
                                return {
                                    _id: p.id,
                                    name: p.label
                                }
                            })
                            console.log(newArray)
                            setCurrentProvinces(newArray);

                        }}
                    />
                    <label>Sinonimi testate</label>
                    <textarea
                        value={currentSynonyms}
                        onChange={(e) => {
                            setCurrentSynonyms(e.target.value)
                            setChanged(true)
                        }}
                    />
                </div>
                <div className="showCol">
                    <img src={currentLogo} className="logoSource" />
                    <p
                        className="submitButton"
                        style={{ opacity: changed && currentName && currentFeed ? 1 : 0.4 }}
                        onClick={() => {
                            if (currentId !== -1) {
                                handleEdit()
                            } else {
                                handleCreate()
                            }
                        }}
                    >
                        {currentId !== -1 ? "SALVA" : "AGGIUNGI"}
                    </p>
                    {currentId !== -1 && (
                        <p
                            className="deleteButton"
                            onClick={() => {
                                setModalVisible(true)
                            }}
                        >
                            ELIMINA
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SourceDetailPage