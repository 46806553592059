import { setCountAll, setCountToday } from "./graphic";
import {
  GET_REGIONS,
  GET_TAGS,
  GET_SOURCES,
  GET_COUNT,
  GET_PROVINCES,
} from "./../../res/constants";
import axios from "axios";
import moment from "moment";
import { Reducers } from "../../types/reducers";
import { setProvinces, setRegions, setSources, setTags } from "./data";
require("moment/min/locales");
moment.locale("it");

export const getCount: any = () => async (dispatch: (any: any) => void) => {
  const counters = await axios.get(GET_COUNT).then((res) => {
    return res.data.counters;
  });
  dispatch(setCountToday(counters.today));
  dispatch(setCountAll(counters.all));
};

export const getTags: any = () => async (dispatch: (any: any) => void) => {
  const tags = await axios.get(GET_TAGS).then((res) => {
    return res.data.items;
  });
  dispatch(setTags(tags));
};

export const getRegions: any = () => async (dispatch: (any: any) => void) => {
  const regions = await axios.get(GET_REGIONS).then((res) => {
    return res.data.items;
  });
  dispatch(setRegions(regions));
};

export const getProvinces: any = () => async (dispatch: (any: any) => void) => {
  const provinces = await axios.get(GET_PROVINCES).then((res) => {
    return res.data.items;
  });
  dispatch(setProvinces(provinces));
};

export const getSources: any = () => async (dispatch: (any: any) => void) => {
  const sources = await axios.get(GET_SOURCES).then((res) => {
    return res.data.items;
  });
  dispatch(setSources(sources));
};

export const initData: any = () => async (dispatch: (any: any) => void) => {
  dispatch(getTags());
  dispatch(getRegions());
  dispatch(getSources());
  dispatch(getCount());
  dispatch(getProvinces());
};
