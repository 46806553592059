import { ReduxActionData } from "./../../types/reducers";
import { ReducerUser } from "../../types/reducers";
import { USER_ACTIONS } from "../actions/user";

const defaultValue: ReducerUser = {
  status: false
};

const userState: (
  state: ReducerUser,
  actions: ReduxActionData<any>
) => ReducerUser = (state = defaultValue, action: ReduxActionData<any>) => {
  switch (action.type) {
    case USER_ACTIONS.SET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case USER_ACTIONS.LOGOUT:
      return defaultValue;
    default:
      return state;
  }
};

export default userState;
