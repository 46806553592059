import React from 'react'
import { ExitToApp, Menu } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { setStatus } from '../store/actions/user';
import { isMobile } from 'react-device-detect'
import { LOGO_BLACK } from '../res/constants';
import { setShowMobileMenu } from '../store/actions/graphic';
import { NavLink, useHistory } from 'react-router-dom'

const Header: React.FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    if (isMobile) {
        return (
            <div className="header-mobile">
                <NavLink to="/" style={{ width: "35%" }}>
                    <img src={LOGO_BLACK} className="logo" />
                </NavLink>
                <div>
                    <Menu style={{ fontSize: 32, cursor: "pointer", color: "#000", marginRight: 20 }} onClick={() => dispatch(setShowMobileMenu(true))} />
                    <ExitToApp style={{ fontSize: 32, cursor: "pointer", color: "#fd5468" }}
                        onClick={() => {
                            dispatch(setStatus(false))
                            history.push("/")
                        }} />
                </div>
            </div>
        )
    }

    return (
        <div className="header">
            <ExitToApp
                style={{ fontSize: 32, cursor: "pointer", color: "#fd5468" }}
                onClick={() => {
                    dispatch(setStatus(false))
                    history.push("/")
                }} />
        </div>
    )
}

export default Header