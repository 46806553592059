import { DATA_ACTIONS } from "./../actions/data";
import { ReducerData, ReduxActionData } from "../../types/reducers";

const defaultValue: ReducerData = {
  sources: [],
  regions: [],
  tags: [],
  provinces: [],
};

const dataState: (
  state: ReducerData,
  actions: ReduxActionData<any>
) => ReducerData = (state = defaultValue, action: ReduxActionData<any>) => {
  switch (action.type) {
    case DATA_ACTIONS.SET_SOURCES:
      return {
        ...state,
        sources: action.payload,
      };
    case DATA_ACTIONS.SET_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };
    case DATA_ACTIONS.SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case DATA_ACTIONS.SET_PROVINCES:
      return {
        ...state,
        provinces: action.payload,
      };
    default:
      return state;
  }
};

export default dataState;
