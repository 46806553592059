import { Region, Source, Tag } from '../../types/data';
import { ReduxAction } from './../../types/reducers';

export enum USER_ACTIONS {
    SET_STATUS = "userActions/setStatus",
    LOGOUT = "userActions/logout"
}

export const setStatus: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: USER_ACTIONS.SET_STATUS,
        payload: status
    }
}

export const cleanFilter = () => {
    return {
        type: USER_ACTIONS.LOGOUT
    }
}

