import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import SourceCard from '../../components/SourceCard'
import { ReducerData, Reducers } from '../../types/reducers'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Source } from '../../types/data'
import { Search } from '@material-ui/icons'

const SourcesPages: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const history = useHistory()
    const [filterQuery, setFilterQuery] = useState("")

    const sources = useMemo(() => {
        return (
            data.sources.filter((s) => s.name.toLowerCase().includes(filterQuery))
        )
    }, [data.sources, filterQuery])

    return (
        <>
            <div className="titleContainer">
                <h1 className="titleSection">Testate</h1>
                <p className="add" onClick={() => {
                    const newSource: Source = {
                        _id: -1,
                        name: "",
                        feed: "",
                        img: "",
                        region: null,
                        tag: null,
                        provinces: [],
                        synonyms: ""
                    }
                    history.push("/sources/detail", { source: newSource })
                }}>+ Aggiungi testata</p>
            </div>
            <div className="filterContainer">
                <h2>Filtra</h2>
                <div style={{ position: "relative" }}>
                    <Search style={{ position: "absolute", color: "#666" }} />
                    <input placeholder="Nome testata" onChange={(e) => setFilterQuery(e.target.value)} />
                </div>
            </div>
            <Grid container spacing={2}>
                {sources.map((s) => {
                    return (
                        <Grid key={s._id.toString()} item xs={12} lg={3}>
                            <SourceCard source={s} />
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}

export default SourcesPages