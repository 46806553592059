import { Province } from "./../types/data";

export const itemsForSelect = (array: Province[]) => {
  if (array == []) {
    return;
  }
  const arrayFormatted = array.map((item) => {
    return { id: item._id, value: item._id, label: item.name };
  });
  return arrayFormatted;
};

export const getDatasAndSelect = (provinces: Province[]) => {
  const arrayFormatted = itemsForSelect(provinces);
  return arrayFormatted;
};
